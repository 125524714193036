<template>
	<section class="crear-cupones-vendedor">
		<div class="row mx-0 py-2">
			<div class="col-auto">
				<div class="btn-back" @click="$router.push({ name: 'tendero.cupones' });">
					<i class="icon-left f-12" />
				</div>
			</div>
			<div v-if="!edit" class="col pl-0 text-general f-18">
				Crear Cupón
			</div>
			<div v-else class="col pl-0 text-general f-18">
				Editar Cupón
			</div>
		</div>
		<div class="custom-scroll overflow-auto pt-3">
			<div class="row mx-0">
				<div class="col-12 col-lg-8">
					<ValidationObserver ref="validarCupon" tag="div">
						<div class="bg-white br-8 py-3 px-5">
							<p class="f-18 f-500 text-general mb-3">Detalle del cupón</p>
							<div class="row">
								<div class="col-9">
									<ValidationProvider v-slot="{ errors }" rules="required|max:40" name="Nombre" tag="div"
										class="col-10">
										<p class="f-12 text-general pl-2">Nombre de cupón</p>
										<el-input v-model="model.nombre" placeholder="Nombre" class="w-100 h-36px br-8" />
										<p class="text-danger f-12 pl-3"> {{ errors[0] }} </p>
									</ValidationProvider>
								</div>
								<div class="col-3">
									<ValidationProvider v-slot="{ errors }" rules="required|max:25" name="Código" tag="div"
										class="col-10">
										<p class="f-12 text-general pl-2">Código</p>
										<el-input v-model="model.codigo" placeholder="Código" class="w-100 h-36px br-8" />
										<p class="text-danger f-12 pl-3"> {{ errors[0] }} </p>
									</ValidationProvider>
								</div>
							</div>
							<div class="row my-4">
								<div class="col-12">
									<ValidationProvider v-slot="{ errors }" rules="required|max:500" name="Descripción"
										tag="div" class="col-10">
										<p class="f-12 text-general pl-2">Mensaje del descuento</p>
										<el-input v-model="model.mensaje" type="textarea" :rows="4"
											:placeholder="`Mensaje al ${$config.vendedor} / ${$config.cliente}`"
											class="w-100 br-8" />
										<p class="text-danger f-12 pl-3"> {{ errors[0] }} </p>
									</ValidationProvider>
								</div>
							</div>
							<div class="row">
								<div class="col-6">
									<ValidationProvider v-slot="{ errors }" rules="required" name="Descuento" tag="div"
										class="col-10">
										<p class="f-12 text-general pl-2">Descuento %</p>
										<el-select v-model="value" class="w-100 h-36px" placeholder="Selecciona">
											<el-option v-for="item in options" :key="item.id" :label="`${item.descuento}%`"
												:value="item.descuento" />
										</el-select>
										<p class="text-danger f-12 pl-3"> {{ errors[0] }} </p>
									</ValidationProvider>
								</div>
								<div class="col-6">
									<p class="f-12 text-general pl-2">Límite de uso por cupón</p>
									<el-input v-model="model.limite" placeholder="Cantidad" class="w-100 h-36px br-8"
										:disabled="model.sinLimite" />
									<el-checkbox v-model="model.sinLimite" label="Sin limite" size="large"
										class="pl-2 mt-1" />
								</div>
							</div>
						</div>
					</ValidationObserver>
				</div>
			</div>
			<!--  -->
			<div class="row mx-0 mt-5">
				<div class="col-12 col-lg-8">
					<div class="bg-white br-8 py-3 px-5">
						<p class="f-18 f-500 text-general mb-3">Limites del uso del cupón</p>
						<div class="row">
							<div class="col-5 my-auto">
								<p class="f-15 text-general pl-2">Descuento máximo aplicado</p>
							</div>
							<div class="col-auto">
								<div>
									<el-input v-model="model.maximo" placeholder="Valor" class="w-175px h-36px br-8" />
									<el-tooltip placement="bottom" effect="light" :show-arrow="false" :offset="-5">
										<div slot="content">
											<p class="w-235px px-2">Cuando se supere este valor, el descuento se aplicará a
												este máximo.</p>
										</div>
										<i class="icon-alert-circled ml-2" />
									</el-tooltip>
								</div>
							</div>
						</div>
						<div class="row mt-4">
							<div class="col-5 my-auto">
								<p class="f-15 text-general pl-2">Vigencia</p>
							</div>
							<div class="col-auto">
								<p class="f-12 pl-2">Inicio</p>
								<el-date-picker v-model="value1" type="datetime" placeholder="Fecha y hora"
									class="w-175px h-36px" />
							</div>
							<div class="col-auto">
								<p class="f-12 pl-2">Fin</p>
								<el-date-picker v-model="value2" type="datetime" placeholder="Fecha y hora"
									class="w-175px h-36px" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mx-0 mt-3 ">
				<div class="col-12 col-lg-8 text-right">
					<button v-if="!edit" class="bg-general cr-pointer f-500 h-36px br-8 f-16 px-3 py-1 border-0 w-137px text-white"
						@click="postCupon">
						Crear
					</button>
					<button v-else class="bg-general cr-pointer f-500 h-36px br-8 f-16 px-3 py-1 border-0 w-137px text-white"
						@click="editCupon">
						Editar
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Service from "~/services/cupones/cupones";
export default {
	components: {
		modalCrearCarpeta: () => import('./partials/modalCrearCarpeta')
	},
	data() {
		return {
			model: {
				nombre: '',
				codigo: '',
				mensaje: '',
				maximo: null,
				limite: null,
				sinLimite: false,
			},
			value: '',
			value1: null,
			value2: null,
			options: []
		}
	},
	computed: {
		edit() {
			if (this.$route.name === 'tendero.cupones.editar') return true
			if (this.$route.name === 'tendero.cupones.crear') return false
			return 0
		}
	},
	mounted() {
		this.getSelectDescuentos()
		if(this.edit) this.getCuponDetalle()
	},
	methods: {
		async getSelectDescuentos() {
			try {
				const { data } = await Service.getSelectDescuento(this.$usuario.tienda.id_cedis)
				this.options = data.data
			} catch (error) {
				return this.error_catch(e)
			}
		},
		async postCupon() {
			try {
				const valid = await this.$refs.validarCupon.validate();
				if (!valid) return this.notificacion('', 'campos obligatorios', 'warning');
				const payload = {
					tipo: 1,
					nombre: this.model.nombre,
					codigo: this.model.codigo,
					descripcion: this.model.mensaje,
					descuento: this.value,
					id_carpeta: this.$route.params.idCarpeta,
					fechaInicio: this.value1,
					fechaFin: this.value2,
					limite: this.model.sinLimite ? 0 : this.model.limite,
					maximo: this.model.maximo
				}
				const { data } = await Service.crearCupon(payload);
				this.$router.push({ name: 'tendero.cupones.detalles', params:{idCupon: data.cupon.id} })
			} catch (e) {
				return this.error_catch(e)
			}
		},
		async editCupon() {
			try {
				const valid = await this.$refs.validarCupon.validate();
				if (!valid) return this.notificacion('', 'campos obligatorios', 'warning');
				const payload = {
					tipo: 1,
					nombre: this.model.nombre,
					codigo: this.model.codigo,
					descripcion: this.model.mensaje,
					descuento: this.value,
					id_carpeta: this.$route.params.idCarpeta,
					fechaInicio: this.value1,
					fechaFin: this.value2,
					limite: this.model.sinLimite ? 0 : this.model.limite,
					maximo: this.model.maximo
				}
				const { data } = await Service.editarCupon(payload, this.$route.params.idCupon);
				this.$router.push({ name: 'tendero.cupones.detalles', params:{idCupon: data.cupon.id} })
			} catch (e) {
				return this.error_catch(e)
			}
		},
		async getCuponDetalle(){
			try {
				const { data } = await Service.getDatosBasicos(this.$route.params.idCupon)
				this.mapData(data.cupon);
 			} catch (e) {
				return this.error_catch(e)
			}
		},
		mapData(data){
			this.model = {
				nombre: data.nombre,
				codigo: data.codigo,
				mensaje: data.descripcion,
				maximo: data.compra_maxima,
				limite: data.limite_total,
				sinLimite: data.limite_total == null ? true : false,
			}
			this.value1 = data.fecha_inicio;
			this.value2 = data.fecha_fin;
			this.value = Number(data.descuento)
		}
	}
}
</script>

<style lang="scss" scoped>
.crear-cupones-vendedor {
	background-color: inherit;
}
</style>